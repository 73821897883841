.auth {
  &__header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 32px;
    text-align: center;
  }

  &__container {
    margin: 0 auto;
    max-width: 300px;
  }

  &__info {
    opacity: 0.4;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 16px;
  }

  &__error {
    text-align: center;
    margin-bottom: 16px;
  }

  .four.wide.field {
    margin: auto;
  }

  &__redirect {
    text-align: center;
  }
}