.photo-input-preview {
  input[type=file] {
    display: none;
  }
  &__label {
    display: inline-block;
    position: relative;
    button {
      pointer-events: fill
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__error {
    color: #ff0000;
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
  }
  &__item {
    position: relative;
    width: 110px;
    height: 80px;
    margin: 0 8px 8px 0;
    border: 1px solid #d1d1d1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__delete {
    display: block;
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
  }
}
