.decision {
  border-radius: 4px;
  background-color: #f7f7f7;
  padding: 8px 16px;
  max-width: 640px;

  & + & {
    margin-top: 4px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-bottom: 4px;

    div {
      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        text-align: right;
      }
    }
  }
  &__comment {
    margin: 16px 0;
  }
}