.layout {
  padding-top: 60px;

  &__menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    &__logout {
      cursor: pointer;
    }
  }
}


.content {

  padding: 0 16px;
}