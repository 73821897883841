.users-edit {
  &__form {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
}