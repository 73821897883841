.file-manager {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
  .divider {
    margin-top: 0;
  }
  &__sort {
    display: flex;
    align-items: baseline;

    &.--active {
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 10px 4px;
        border-color: transparent transparent #007bff transparent;
        margin-left: 8px;
        transition: 250ms transform;
      }
    }

    &.--desc {
      &:after {
        transform: rotate(180deg);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}