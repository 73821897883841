.attach {
  display: flex;
  align-items: center;
  margin: 4px 0;

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: '';
    display: block;
    background-image: url("../../../images/attach.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 17px;
    width: 17px;
    margin-right: 8px;
  }

  &:after {
    content: 'Прикрепить';
    color: #244a68;
  }

  input {
    display: none;
  }
}