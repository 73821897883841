.doc-view {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 640px;

  &__filename {
    margin-right: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  &__pdf {
    margin: 0 8px;
    color: #016fe6;
    white-space: nowrap;
  }

  &__spoiler {
    display: flex;
    align-items: baseline;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 10px 6px;
      border-color: transparent transparent #909090 transparent;
      margin-left: 8px;
      transition: 250ms transform;
      transform: rotate(180deg);
    }

    &.--open {
      &:after {
        transform: rotate(0deg);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}