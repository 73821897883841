.users-form {

  &__helperText {
    margin-left: 25px;
    font-size: 12px;
    color: #828282;
  }

  &__select {
    width: calc(100% / 3 * 2);
  }

  &__tabs {
    padding: 12px 0 16px;
    display: flex;
    font-size: 20px;
  }

  &__tab {
    padding: 4px 8px;
    border-bottom: 1px solid #d1d1d1;

    &:hover {
      cursor: pointer;
    }

    &.--active {
      border-color: #016fe6;
    }

    &.--error {
      color: red;
    }
  }

  .--error-pin .ui.checkbox label {
    color: red;
    font-weight: bold;
  }

  &__error {
    color: red;
    font-size: 12px;
  }

  &__photo {
    margin: 8px 0;
  }

  &__action.button {
    margin-bottom: 8px;
    margin-right: 16px;
  }
}

canvas {
  display: none;
}