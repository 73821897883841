.file-manager-view {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }

  .divider {
    margin-top: 0;
  }

  &__title {
    font-size: 12px;
    font-weight: lighter;
    color: #909090;
  }

  &__people {
    max-width: 640px;
    display: flex;
    flex-wrap: wrap;

    div {
      margin: 4px 8px 4px 0;

      &:after {
        content: ', ';
      }
    }
  }
}

$cerulean-blue: #244a68;

.coordination {
  &__files_more {
    margin-top: 8px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $cerulean-blue;
    letter-spacing: normal;
    font-stretch: normal;

    &.--final-approver, &.--executor {
      margin-bottom: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}