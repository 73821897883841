.logout {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 46px 0;
  flex-direction: column;

  &__logo {
    order: 1;
    width: 89px;
    height: 89px;
    background-image: url("../../images/logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__message {
    order: 3;
    margin-top: 36px;
    font-size: 22px;
  }

  &__redirect-message {
    order: 3;
    margin-top: 36px;
    font-size: 14px;
  }
}